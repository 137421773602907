<template>
  <div id="RangchaList">
    <!-- 让茶 -->
    <div>
      <div class="banner">
        <img
          src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/banner_rangcha.jpg"
          alt=""
          srcset=""
        />
      </div>

       <!-- 无糖果茶系列 -->
       <div id="series_fruit" class="bg_huacha series_b" style="margin-top: 72px;margin-bottom: 570px;">
        <div class="series_title" style="height: 200px">无糖果茶系列</div>
        <div class="series_dec">
          <div class="dec_item">海拔800米以上</div>
          <div class="dec_item">高山茶园</div>
          <div class="dec_item">维生素</div>
          <div class="dec_item"> C100mg/500ml,</div>
          <div class="dec_item">满足成人每日所需</div>
          <div class="dec_item">100%真茶萃取</div>
          <div class="dec_item">0糖0能量 好喝不怕胖</div>
        </div>
        <div class="item_box bt">
          <div class="item" @click="toDetail(1, 9)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/22ping3.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">葡萄乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 16)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/22ping2.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">柠C茉莉茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 18)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/22ping5.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">柚C茉莉茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 15)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/22ping4.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">青梅龙井茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" style="margin-top: 10px" @click="toDetail(1, 19)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/22ping1.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">橙C乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          
        </div>
      </div>
       <!-- 饭后茶系列 -->
       <div id="series_herb" class="bg_e series_b" style="margin-top: 72px">
        <div class="series_title" style="height: 200px">饭后茶系列</div>
        <div class="series_dec">  
          <div class="dec_item">搭配植物食材，</div>
          <div class="dec_item">饭后一瓶自然轻爽</div>
          <div class="dec_item">海拔800米以上高山茶园</div>
          <div class="dec_item">原叶茶萃取</div>
          <div class="dec_item">0糖0能量</div>
        </div>
        <div class="item_box">
          <div class="item" @click="toDetail(1, 20)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/puertea1.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">饭后普洱</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>

          <div class="item" @click="toDetail(1, 21)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/puertea2.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">饭后乌龙</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 高山茶系列 -->
      <div id="series_mountain" class="bg_c series_b" style="margin-top: 72px">
        <div class="series_title" style="height: 200px">高山茶系列</div>
        <div class="series_dec">
          <div class="dec_item">海拔800米以上高山茶园</div>
          <div class="dec_item">0糖0能量</div>
          <div class="dec_item">不添加香精不添加防腐剂</div>
          <div class="dec_item">原叶茶萃取</div>
          <div class="dec_item">清爽甘甜少苦涩</div>
        </div>
        <div class="item_box">
          <div class="item" @click="toDetail(1, 12)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/alp2.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">茉莉乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 13)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/alp3.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">竹香乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 14)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/alp1.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">桂花乌龙茶</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 养生水系列 -->
      <div id="series_water" class="bg_b series_a">
        <div class="series_title" style="height: 238px">轻养水系列</div>
        <div class="series_dec">
          <div class="dec_item">传统熬煮工艺</div>
          <div class="dec_item">配料干净</div>
          <div class="dec_item">0糖0脂0能量</div>
          <div class="dec_item">冷热都好喝</div>
        </div>
        <!-- benner -->
        <div class="item_box">
          <div class="item" @click="toDetail(1, 22)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/regimen1.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">红豆薏米水</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
          <div class="item" @click="toDetail(1, 23)">
            <div class="img_box">
              <img
                src="https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/regimen2.png"
                alt=""
                srcset=""
              />
            </div>
            <div class="title">
              <div class="title_left">红枣枸杞水</div>
              <div class="title_right">
                <img src="../../assets/product/tuxing.png" alt="" srcset="" />
              </div>
            </div>
          </div>
        </div>
      </div> 
      <!-- 他山集 -->
    </div>
    <PCConncatUs />
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import $ from "jquery";
import PCConncatUs from "@/components/PCConncatUs.vue";
export default {
  name: "RangchaList",
  components: {
    swiper,
    swiperSlide,
    PCConncatUs,
  },
  props: {},
  setup() {},
  data() {
    return {
      swiperOption: {
        slidesPerView: 3,
        loop: true,
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          hideOnClick: true,
        },
      },
    };
  },
  methods: {
    toDetail(type, p_id) {
      this.$router.push(`/product/rangcha/detail?pid=${p_id}`);
    },
  },
  watch: {
    $route(newValue) {
      let height, banner_height;
      if (this.$route.query.h) {
        /*  banner_height = $(".banner").height(); */
        if (newValue.query.h == 1) {
          height = "60px";
        } else {
          height = (Number(newValue.query.h) - 1) * 600 + 60;
        }
      } else {
        height = "60px";
      }
      $("body,html").animate({ scrollTop: height }, 500);
      let _height = $(document).scrollTop();
    },
  },
  created() {
    this.$nextTick(() => {
      let height = 60;
      if (this.$route.query.h) {
        const banner = $(".banner");
        const seriesMap = {
          1: "#series_fruit",
          2: "#series_herb",
          3: "#series_mountain",
          4: "#series_water"
        };
        const targetId = seriesMap[this.$route.query.h];
        
        if (targetId) {
          const targetElement = $(targetId);
          if (targetElement.length > 0) {
            // 获取banner的位置信息
            const bannerRect = banner[0].getBoundingClientRect();
            height = bannerRect.top + window.scrollY;
            
            // 获取目标元素的位置
            const targetRect = targetElement[0].getBoundingClientRect();
            height += targetRect.top;
            
            // 添加适当的偏移量以确保视觉效果
            height -= 265;
          }
        }
        $("body,html").animate({ scrollTop: height }, 500);
      }
    });
  },
  watch: {
    $route(newValue) {
      this.$nextTick(() => {
        let height = 60;
        if (newValue.query.h) {
          const banner = $(".banner");
          const seriesMap = {
            1: "#series_fruit",
            2: "#series_herb",
            3: "#series_mountain",
            4: "#series_water"
          };
          const targetId = seriesMap[newValue.query.h];
          
          if (targetId) {
            const targetElement = $(targetId);
            if (targetElement.length > 0) {
              // 获取banner的位置信息
              const bannerRect = banner[0].getBoundingClientRect();
              height = bannerRect.top + window.scrollY;
              
              // 获取目标元素的位置
              const targetRect = targetElement[0].getBoundingClientRect();
              height += targetRect.top;
              
              // 添加适当的偏移量以确保视觉效果
              height -= 265;
            }
          }
          $("body,html").animate({ scrollTop: height }, 500);
        }
      });
    }
  },
  mounted() {
    $("body,html").animate({ scrollTop: "60px" }, 0);
  },
};
</script>

<style scoped lang="less">
#RangchaList {
  .banner {
    width: 100%;
    /* height: 200px; */
    img {
      width: 100%;
      /*  height: 100%; */
    }
  }
  .series_b, .series_a, .series_c, .series_d {
    width: 100%;
    min-height: 320px;
    position: relative;
    margin-bottom: 284px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 63px 0;
    box-sizing: border-box;
    .series_title {
      width: 60px;
      height: 142px;
      background: #45B035;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      writing-mode: vertical-lr;
      text-align: center;
      line-height: 60px;
      letter-spacing: 5px;
      position: absolute;
      top: -37px;
      left: 24px;
      z-index: 998;
    }
    .series_dec {
      width: 260px;
      height: 250px;
      position: absolute;
      top: 10px;
      left: 122px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      z-index: 998;
      .dec_item {
        width: 24px;
        height: 100%;
        writing-mode: vertical-lr;
        letter-spacing: 6px;
        float: left;
        margin-right: 12px;
      }
    }
    .item_box {
      width: 890px;
      height: 263px;
      position: absolute;
      left: 20px;
      top: 225px;
      cursor: pointer;
      z-index: 998;
      .item {
        width: 204px;
        height: 292px;
        float: left;
        margin-right: 14px;
        cursor: pointer;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        background: white;
        .img_box {
          width: 196px;
          height: 252px;
          overflow: hidden;
          img {
            width: 100%;
            height: 252px;
            transition: all 1s;
          }
        }
        .title {
          width: 196px;
          height: 36px;
          background: #fff;
          .title_left {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 36px;
          }
          .title_right {
            float: right;
            width: 36px;
            height: 36px;
            line-height: 30px;
            transition: all 1s;
            img {
              width: 36px;
              height: 4px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.1);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
    }
    .model {
      width: 100%;
      height: 320px;
      position: absolute;
      left: 0;
      top: 0;
      background: black;
      opacity: 0.4;
      z-index: 997;
    }
  }

  #series_a,
  #series_b,
  #series_c,
  #series_d {
    width: 100%;
    min-height: 320px;
    position: relative;
    margin-bottom: 284px;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 230px 63px 0;
    box-sizing: border-box;
    .series_title {
      width: 60px;
      height: 142px;
      background: #45B035;
      font-size: 20px;
      font-weight: 500;
      color: #ffffff;
      writing-mode: vertical-lr;
      text-align: center;
      line-height: 60px;
      letter-spacing: 5px;
      position: absolute;
      top: -37px;
      left: 24px;
      z-index: 998;
    }
    .series_dec {
      width: 260px;
      height: 250px;
      position: absolute;
      top: 10px;
      left: 122px;
      font-size: 16px;
      font-weight: 400;
      color: #ffffff;
      z-index: 998;
      .dec_item {
        width: 24px;
        height: 100%;
        writing-mode: vertical-lr;
        letter-spacing: 6px;
        float: left;
        margin-right: 12px;
      }
    }
    .item_box {
      width: calc(100% - 400px);
      margin-left: auto;
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: flex-start;
      padding-right: 20px;
      .item {
        width: calc((100% - 60px) / 4);
        min-width: 200px;
        height: 292px;
        cursor: pointer;
        padding: 4px 4px 0 4px;
        box-sizing: border-box;
        background: white;
        .img_box {
          width: 196px;
          height: 252px;
          overflow: hidden;
          img {
            width: 100%;
            height: 252px;
            transition: all 1s;
          }
        }
        .title {
          width: 196px;
          height: 36px;
          background: #fff;
          .title_left {
            float: left;
            font-size: 16px;
            font-weight: 400;
            color: #121212;
            line-height: 36px;
          }
          .title_right {
            float: right;
            width: 36px;
            height: 36px;
            line-height: 30px;
            transition: all 1s;
            img {
              width: 36px;
              height: 4px;
            }
          }
        }
        &:hover {
          .img_box {
            img {
              transform: scale(1.1);
            }
          }
          .title {
            .title_right {
              transform: translateX(5px);
              opacity: 0;
            }
          }
        }
      }
    }
    .model {
      width: 100%;
      height: 320px;
      position: absolute;
      left: 0;
      top: 0;
      background: black;
      opacity: 0.4;
      z-index: 997;
    }
  }
.bt{
  padding-bottom:10px;
}
  .bg_a {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_a.jpg");
  }
  .bg_b {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background-image4.jpg");
  }
  .bg_c {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background-image3.jpg");
  }
  .bg_huacha {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background-image1.jpg");
  }
  .bg_d {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/rangcha_series_banner_d.jpg");
  }
  .bg_e {
    background-image: url("https://rangcha-oss.oss-cn-beijing.aliyuncs.com/v3_website/product/product-background-image2.jpg");
  }
}
</style>

